import React from "react";
import Gallery from "react-photo-gallery";
import Lightbox from 'react-images';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';


import mural1 from '../../imgs/Gallery/m2.jpg';
import mural2 from '../../imgs/Gallery/m1.jpg';
import mural3 from '../../imgs/Gallery/m4.jpg';
import mural4 from '../../imgs/Gallery/stairwellpanel.jpg';
import mural5 from '../../imgs/Gallery/cher.jpg';
import mural6 from '../../imgs/Gallery/Fireplace.jpg';
import mural7 from '../../imgs/Gallery/Image42.jpg';
import mural8 from '../../imgs/Gallery/theclown.jpg';
import mural9 from '../../imgs/Gallery/thefairy.jpg';
import mural10 from '../../imgs/Gallery/wallpaperlarge.jpg';
import mural11 from '../../imgs/Gallery/Image12.jpg';
import mural12 from '../../imgs/Gallery/Image9.jpg';
import mural13 from '../../imgs/Gallery/Image40.jpg';
import mural14 from '../../imgs/Gallery/Image40c.jpg';
import mural15 from '../../imgs/Gallery/Image43.jpg';
import mural16 from '../../imgs/Gallery/pic23.jpg';
const styles = theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
});

const photos = [
  {
    src: `${mural1}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural2}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural5}`,
    width: 3,
    height: 4
  },
  {
    src: `${mural6}`,
    width: 3,
    height: 4
  },
  {
    src: `${mural3}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural4}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural8}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural9}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural7}`,
    width: 3,
    height: 4
  },
  {
    src: `${mural10}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural14}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural11}`,
    width: 4,
    height: 3
  },
  {
    src: `${mural13}`,
    width: 3,
    height: 3
  },
  {
    src: `${mural12}`,
    width: 3,
    height: 4
  },
  {
    src: `${mural16}`,
    width: 4,
    height: 3
  }
];

// to best see the results, click the popout button in the preview browser
// and resize your browser

function columns(containerWidth) {
  let columns = 1;
  if (containerWidth >= 500) columns = 2;
  if (containerWidth >= 900) columns = 3;
  if (containerWidth >= 1500) columns = 4;
  return columns;
}



class MuralGalleryDisplay  extends React.Component {
    constructor(props) {
      super(props);
      this.state = { currentImage: 0 };
      this.closeLightbox = this.closeLightbox.bind(this);
      this.openLightbox = this.openLightbox.bind(this);
      this.gotoNext = this.gotoNext.bind(this);
      this.gotoPrevious = this.gotoPrevious.bind(this);
    }
    openLightbox(event, obj) {
      this.setState({
        currentImage: obj.index,
        lightboxIsOpen: true,
      });
    }
    closeLightbox() {
      this.setState({
        currentImage: 0,
        lightboxIsOpen: false,
      });
    }
    gotoPrevious() {
      this.setState({
        currentImage: this.state.currentImage - 1,
      });
    }
    gotoNext() {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    }
    render() {
      const { classes } = this.props;
      return (
    
         <React.Fragment> 
          <CssBaseline />
          <div className={classes.layout}>
          <Gallery photos={photos} onClick={this.openLightbox} columns={columns}/>
          <Lightbox images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
        </React.Fragment>
      )
    }
  }
  
export default withStyles(styles)(MuralGalleryDisplay);