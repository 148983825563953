import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LinkUI from '@material-ui/core/Link';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Explore';
import Home from './pages/Home';
import Theatre from './pages/Theatre';
import Gallery from './pages/Gallery';
import Murals from './pages/Murals';
import Masks from './pages/Masks';
import Current from './pages/Current';
import References from './pages/References';
import TheatreGallery from './pages/TheatreGallery';
import MuralGallery from './pages/MuralGallery';
import MaskGallery from './pages/MaskGallery';

//import bg from './imgs/87.jpg';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  button: {
    margin: theme.spacing.unit/2,
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  root: {
    flexGrow: 1,
    //backgroundImage: `url(${bg})`

   // background: 'linear-gradient(to right, #8f94fb, #4e54c8)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    //background: 'linear-gradient(to right, #0575e6, #021b79)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    //background: 'linear-gradient(45deg, #304352, #d7d2cc)'
    background: 'linear-gradient(to left, #4b6cb7, #182848)'
    
  },
  grow: {
    flexGrow: 2,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
});

const HomeLink = props => <Link to="/" {...props} />


class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
      };
      this.handleDrawerClick = this.handleDrawerClick.bind(this);
    }
    handleDrawerClick() {
      if (this.state.open === false)
        this.setState({ open: true });
      else
        this.setState({ open: false });
    }
  
    render() {
    const { classes } = this.props;
    //const { auth, anchorEl } = this.state;
    //const open = Boolean(anchorEl);
    return (
      
         <Router>
      <div>
       <CssBaseline />
       <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={this.handleDrawerClick.bind()}>
              <MenuIcon />
            </IconButton>
            <Typography className={classNames(classes.title, classes.grow)} variant="h6" color="inherit" noWrap>
              <LinkUI component={HomeLink} color="inherit">
              Alexander McPherson
              </LinkUI>
            </Typography>
            <Hidden smDown>
            <Button color="inherit" variant="contained" color="secondary" component={Link} className={classes.button} to="/theatre">Theatre</Button>
            <Button color="inherit" variant="contained" color="secondary" component={Link} className={classes.button} to="/masks">Masks</Button>
            <Button color="inherit" variant="contained" color="secondary" component={Link} className={classes.button} to="/gallery">Galleries</Button>
            <Button color="inherit" variant="contained" color="secondary" component={Link} className={classes.button}to="/references">References</Button> 
             </Hidden>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.open} onClose={this.handleDrawerClick.bind()}>
            <IconButton onClick={this.handleDrawerClick}>
              <ChevronLeftIcon />
            </IconButton>
              <MenuItem component={Link} to="/"> 
                <ListItemIcon className={classes.icon}>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="Home" /></MenuItem>
              <MenuItem component={Link} to="/theatre">
              <ListItemIcon className={classes.icon}>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="Theatre" />
              </MenuItem>
              <MenuItem component={Link} to="/masks"> 
                <ListItemIcon className={classes.icon}>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="Masks" />
              </MenuItem>
              <MenuItem component={Link} to="/gallery">
              <ListItemIcon className={classes.icon}>
                <SendIcon />
               </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="Gallery" />
              </MenuItem>
              <MenuItem component={Link} to="/references">   
                <ListItemIcon className={classes.icon}>
                <SendIcon />
               </ListItemIcon>
                <ListItemText classes={{ primary: classes.primary }} inset primary="References" />
              </MenuItem>
 
        </Drawer>
        <Route exact path="/" component={Home} />
        <Route exact path="/theatre" component={Theatre} />
        <Route exact path="/masks" component={Masks} />
        <Route exact path="/murals" component={Murals} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/current" component={Current} />
        <Route exact path="/theatregallery" component={TheatreGallery} />
        <Route exact path="/muralgallery" component={MuralGallery} />
        <Route exact path="/maskgallery" component={MaskGallery} />
        <Route exact path="/references" component={References} />
        <Route exact path='/teachyourself' component={() => { window.location = 'http://teachyourselfacting.com'; return null;} }/>
        {/* Footer */}
        <footer className={classes.footer}>
          <Typography variant="h6" align="center" gutterBottom>
             For all theatre work Alexander McPherson is represented by Richard Ireson, The Narrow Road Company, 1st Floor, 37 Great Queen St, Covent Garden, London WC2B 5AA
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Email: agents@narrowroad.co.uk Phone: 020 7831 4450 ©2019 Alexander McPherson
          </Typography>
        </footer>
        {/* End footer */}
        </div>
        </div>
          </Router>
     
    
    );
    }
}



App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);