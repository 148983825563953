import React from "react";
import Gallery from "react-photo-gallery";
import Lightbox from 'react-images';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';


import mask1 from '../../imgs/Image45.jpg';
import mask2 from '../../imgs/image46.jpg';
import mask3 from '../../imgs/img34.jpg';
import mask4 from '../../imgs/Image154.jpg';
import mask5 from '../../imgs/Image155.jpg';
import mask6 from '../../imgs/mask.jpg';
import mask7 from '../../imgs/kingmouse.jpg';
import mask8 from '../../imgs/Image43.jpg';
import mask9 from '../../imgs/Image44.jpg';

const styles = theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
});

const photos = [
  {
    src: `${mask1}`,
    width: 3,
    height: 3
  },
  {
    src: `${mask2}`,
    width: 1,
    height: 1
  },
  {
    src: `${mask4}`,
    width: 3,
    height: 4
  },
  {
    src: `${mask5}`,
    width: 3,
    height: 4
  },
  {
    src: `${mask3}`,
    width: 3,
    height: 3
  },
  {
    src: `${mask7}`,
    width: 4,
    height: 3
  },
  {
    src: `${mask6}`,
    width: 4,
    height: 3
  },
  {
    src: `${mask8}`,
    width: 3,
    height: 4
  },
  {
    src: `${mask9}`,
    width: 3,
    height: 4
  }
];

// to best see the results, click the popout button in the preview browser
// and resize your browser

function columns(containerWidth) {
  let columns = 1;
  if (containerWidth >= 500) columns = 2;
  if (containerWidth >= 900) columns = 3;
  if (containerWidth >= 1500) columns = 4;
  return columns;
}



class MaskGalleryDisplay  extends React.Component {
    constructor(props) {
      super(props);
      this.state = { currentImage: 0 };
      this.closeLightbox = this.closeLightbox.bind(this);
      this.openLightbox = this.openLightbox.bind(this);
      this.gotoNext = this.gotoNext.bind(this);
      this.gotoPrevious = this.gotoPrevious.bind(this);
    }
    openLightbox(event, obj) {
      this.setState({
        currentImage: obj.index,
        lightboxIsOpen: true,
      });
    }
    closeLightbox() {
      this.setState({
        currentImage: 0,
        lightboxIsOpen: false,
      });
    }
    gotoPrevious() {
      this.setState({
        currentImage: this.state.currentImage - 1,
      });
    }
    gotoNext() {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    }
    render() {
      const { classes } = this.props;
      return (
    
         <React.Fragment> 
          <CssBaseline />
          <div className={classes.layout}>
          <Gallery photos={photos} onClick={this.openLightbox} columns={columns}/>
          <Lightbox images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
        </React.Fragment>
      )
    }
  }
  
export default withStyles(styles)(MaskGalleryDisplay);