import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import indigo from '@material-ui/core/colors/indigo';
import Hidden from '@material-ui/core/Hidden';

import imgone from '../imgs/Image40d.jpg';
import imgtwo from '../imgs/arcadianmural.jpg';
import imgthree from '../imgs/cher.jpg';
import dec from '../imgs/m2.jpg';
import amcp from '../imgs/amoh.jpg';
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
     backgroundColor: theme.palette.grey[50],
  },
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3,
    padding:  theme.spacing.unit,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 400,
  //paddingTop: '56.25%', // 16:9, marginTop:'30' 
   // height: 400,
    //width: 400,
  },
  sideCardMedia: {
    height: 300,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  }, 

  paper: {
    padding: 2
  },
  paperportrait: {
    padding: 2,
    width:'60%',
    margin: 'auto',
  }
 
});


function Murals(props) {
    const { classes } = props;
  
    return (
      <React.Fragment> 
       <CssBaseline />
       <div className={classes.layout}>
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                 
                  <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  Murals & Decorative Work
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                  Murals & Decorative Work
                  </Typography>
                </Hidden>
                </div>
              </Grid>
            </Grid>
        </Paper>
        
        <Grid container spacing={40} className={classes.mainGrid}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
           To Alexander McPherson, it is the pleasure of providing something integral to the fabric of a room or building, rather than brought in and superimposed, that is the great advantage of murals and specially commissioned painted friezes and decorative effects. Additionally, there is the opportunity they provide for clients to have created for them an individual design, pattern, or even an entire view or scene, which is absolutely unique to them. Alexander works with them to interpret and expand on their original vision, and to blend imagination with technique. As designer, he will match idea and style to the nature of the building or object, to its position, and to the personalities and life style of the owners. He will be able to give life to something which the clients have only perceived in their mind's eye, or he might perhaps suggest something entirely new based on his discussions with them.
          </Typography>
         
          <Paper className={classes.paper}> 
                <img src={imgtwo} alt="Arcadian Mural" style={{width:'100%', height:'auto'}}/>

            </Paper>
          <Typography variant="body2" className={classes.textBlock} gutterBottom>
          Alexander's background is in set and costume design for theatre and he has worked for many regional, West End, and touring companies and in British and in international opera. This has allowed him to acquire the skills and technical knowledge which have provided him with the resources and versatility that have subsequently enabled him to work outside theatre in the field of decorative painted effects, murals, painted panelling, grisaille, and trompe l'oeil.
          </Typography>
          <Typography variant="body2" className={classes.textBlock} gutterBottom>
          In these areas his work has included designing and painting a triptych based on Baroque Opera for a cruise ship, designing a series of murals based on "The Four Seasons" and on "Fantasy Flight" for children's wards in a hospital, designing a "Tuscan Garden" mural for the dining room of a mansion, and designing a large mural based on "The Faraway Tree" by Enid Blyton, to cover four walls of a child's bedroom. He has made something of a speciality out of painted panelling, wood grained effects, and "faux" marble, and he enjoys the more whimsical items such as clouds on ceilings, fantastical or Arcadian scenes, and baroque style ornamentation, always matching style, technique and materials to the commission.
          </Typography>
          <Paper className={classes.paper}> 
                <img src={imgone} alt="Birds" style={{width:'100%', height:'auto'}}/>

            </Paper>
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           ln late 1999 he finished ceiling clouds, marbling and painted panelling for a house in Chelsea, a pair of cartouche murals based on the seasons for the cafe in the Castle Hotel in Windsor, and a trompe l'oeil window to fit into an exterior niche above the front door of a large house in the country. Over 2000/2001 he designed and painted a huge Arcadian mural for the stair way of a flat near Baker Street in London, and for the same flat, clouds and cherubs with swags in a domed ceiling and a number of painted effects, notably gilding and wood graining. During 2002/3 he had a private commission antiquing and distressing wall panelling, and for the same client designing and painting several baroque-style roundels of clouds and cherubs. He designed and executed a series of murals for the interior walls of a swimming pool in the grounds of a private house, featuring local scenes and the house itself. Over 2003/4 he was commissioned to create a design based on the The Bauhaus style, for incorporation into the flooring and walls of the Clerkenwell premises for Drama Centre London and to paint in situ a large mural for the walls of a children's playroom based on "Percy the Park Keeper" but incorporating portraits of the family.
           </Typography>
         
           <Paper className={classes.paperportrait}> 
                <img src={imgthree} alt="Cherub" style={{width:'100%', height:'auto'}}/>

            </Paper>
   
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           He also completed work on a mural for period office building in a Georgian square, featuring a Victorian scene set in the Square itself, and continues to work in the field of trompe l'oeil, marbling, grisaille, wood graining, murals and mask making. He recently completed a specially commission set of masks in the style of the ComMedia del 'Arte.
            </Typography>
         
         
           
          <Divider />
        
        </Grid>
        {/* End main content */}
        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography variant="h6" gutterBottom>
              Alexander McPherson
            </Typography>
           
            <Card className={classes.card}>
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={amcp }
                    title="Alexander McPherson"
                    style={{width:'100%', height:'100%'}}
                  />
                  
           </Card>
          </Paper>
       
          <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
            Mural & Decorative Work Gallery
          </Typography>
        
          <Card className={classes.card}>
              <CardActionArea component={Link} to="/muralgallery">
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={dec}
                    title="Mural"
                  />
              </CardActionArea>
          </Card>
         
          
        </Grid>
        </Grid>
        </div>
      </React.Fragment>
      )
}

export default withStyles(styles)(Murals);