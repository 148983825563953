import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import Hidden from '@material-ui/core/Hidden';

import theatreImg from '../imgs/IOBE2.JPG';
import muralImg from '../imgs/stairwellpanel.jpg';
import maskImg from '../imgs/mask.jpg'
const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
 
 
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  mainFeaturedPost: {
    //backgroundImage: `url(${onei})`,
  //  backgroundRepeat: 'no-repeat',
  //  backgroundSize: 'cover',
  //  backgroundColor: '#534846',
    //backgroundColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
});



function Gallery(props) {
  const { classes } = props;

  return (
    <React.Fragment>
     
     <CssBaseline />
       <div className={classes.layout}>
    
    
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  Gallery
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                  Gallery
                  </Typography>
                </Hidden>
                 
                </div>
              </Grid>
            </Grid>
          </Paper>
        <div className={classNames(classes.layout, classes.cardGrid)}>
      
          {/* End hero unit */}
          <Grid container spacing={40}>
            
            <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={theatreImg}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Theatre Gallery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="medium" variant="contained" color="secondary" className={classes.margin} component={Link} to="/theatregallery">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={maskImg}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Masks Gallery
                    </Typography>
                   
                  </CardContent>
                  <CardActions>
                  <Button size="medium" variant="contained" color="secondary" className={classes.margin} component={Link} to="/maskgallery">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
               
             

              
          </Grid>
        </div>
      </div>
    
    </React.Fragment>
  );
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Gallery);