import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import indigo from '@material-ui/core/colors/indigo';

import kinglear from '../imgs/kinglear.jpg';
import iobe from '../imgs/IOBE1.jpg';
import mikado from '../imgs/mikado.jpg';
import abshell from '../imgs/abshell.jpg';
import design from '../imgs/pic33.jpg';
import amcp from '../imgs/amoh.jpg';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: theme.palette.grey[50],
  },
  mainFeaturedPost: {
  //  backgroundColor: indigo[900],
     backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3,
    padding:  theme.spacing.unit,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 200,
  //paddingTop: '56.25%', // 16:9, marginTop:'30' 
   // height: 400,
    width: 400,
  },
  sideCardMedia: {
    height: 300,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit

  }, 

  paper: {
    padding: 2
  },
  paperportrait: {
    padding: 2,
    width:'60%',
    margin: 'auto',
  }
});


function Theatre(props) {
    const { classes } = props;
  
    return (
      <React.Fragment> 
       <CssBaseline />
       <div className={classes.layout}>
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
               
                <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                    Theatre Set and Costume Design
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                    Theatre Set and Costume Design
                  </Typography>
                </Hidden>
                </div>
              </Grid>
            </Grid>
        </Paper>
        
        <Grid container spacing={40} className={classes.mainGrid}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
            Alexander McPherson has worked in the theatre since the age of 14. For many years he was Head of Design at the Bristol Old Vic, where work included: THE BOY FRIEND, TRELAWNY (also in the West End), THE ROYAL HUNT OF THE SUN, MACBETH (both starring Julian Glover), THE MISER, THE TAMING OF THE SHREW and THE DOUBLE DEALER (also for the Hong Kong Festival) and HEDDA GABLER and TAMING OF THE SHREW (also for a British Council tour of South America/USA and the Hong Kong Festival). He returned as guest designer designing sets and costumes for THE BEGGARS OPERA and the first U.K. production of Anouilh’s THE ARREST with John Hurt.
          </Typography>
         
     
            <Paper className={classes.paper}> 
                <img src={iobe} alt="Imporatance of Being Ernest" style={{width:'100%', height:'auto'}}/>

            </Paper>
              
              
       
          
          <Typography variant="body2" className={classes.textBlock} gutterBottom>
            He spent a year as Head of Design for the Tyne and Wear Theatre Company at the Newcastle Playhouse, and was invited back for Christmas 1987, this time at the New Tyne Theatre, for PETER PAN, for which he was acclaimed for his use of the theatre’s original Victorian stage machinery. He spent three years as Head of Design at the Redgrave Theatre Farnham where plays included; PASSAGE TO INDIA, THE NORMAN CONQUESTS, THE MISTER MEN (also tour), the first version of RE-JOYCE with Maureen Lipman and THE GASS MENAGERIE. From 1989 – 1995 he was Head of Design at the Theatre Royal Windsor. Designs included: the annual pantomimes, (including PUSS IN BOOTS, ALADDIN, CINDERELLA, JACK AND THE BEAN STALK, SLEEPING BEAUTY) and plays such as THE CHALK GARDEN with Helena Bonham-Carter, Eleanor Bron, Michael Denison and Dulcie Gray, PRESENT LAUGHTER with Tom Conti and many others.
          </Typography>
          <Typography variant="body2" className={classes.textBlock} gutterBottom>
            He is well-known for his exotic and witty pantomime designs which he bases on nineteenth century traditions and images and which have been seen in Windsor and also in York, Newcastle, Truro, Barnstaple and elsewhere.
           </Typography>
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           For opera his designs include: for the Welsh National Opera sets and costumes for TURANDOT, THE BEACH AT FALESA (also televised), ALBERT HERRING, A MIDSUMMER NIGHT’S DREAM (also televised), THE MARRIAGE OF FIGARO, IL SERAGLIO and sets for IL TROVATORE; for Opera North DIDO AND AENEAS and A MIDSUMMER NIGHT’S DREAM; and for l’Opera du Rhin (Strasbourg) and l’Opera de Lyon as a joint production Cavalli’s L’OROMINDO; for The Royal Northern College of Music MASTER PETER’S PUPPET SHOW; for Scottish Opera CONFESSIONS OF A JUSTIFIED SINNER; for Opera Clwyd EUGENE ONEGIN. He designed the costumes for a film of THE MIKADO
           </Typography>
          
            <Paper className={classes.paper}> 
                <img src={mikado} alt="Mikado" style={{width:'100%', height:'auto'}}/>

            </Paper>
   
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           Freelance productions for the West End: sets for ARDELE with Vincent Price and Coral Browne, and sets and costumes for THE APPLE CART starring Peter O’Toole, and for DEAD GUILTY by Richard Harris with Jenny Seagrove and Hayley Mills, POOR HORACE and MRS MOUSE ARE YOU WITHIN. Other freelance work work: for Liverpool Playhouse THE HINDLE WAKES, for Guildford sets and costumes for HOBSON’S CHOICE with Derek Jacobi and Wendy Craig, THE MERCHANT OF VENICE, and HEDDA GABLER starring Eileen Atkins; for the Ludlow Festival costumes for THE TAMING OF THE SHREW; the setting for IN PRAISE OF RATTIGAN starring Penelope Keith; for The English Speaking Theatre in Stockholm SHIRLEY VALENTINE; for Southampton SWALLOWS AND AMAZONS and THE BEAUX STRATAGEM, for the Theatre Royal Plymouth THE CURSE OF THE BASKERVILLES, and for the Theatre Royal Windsor THE HAUNTED HOTEL (based on a ghost story by Wilkie Collins), FINAL JUDGEMENT, and APPETITE by Keith Baxter. He has also designed for Hornchurch, Nottingham and Sheffield.
            </Typography>
           
            <Paper className={classes.paperportrait}> 
                <img src={kinglear} alt="King Lear" style={{width:'100%', height:'auto'}}/>

            </Paper>
    
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           National tours include sets and costumes for: THE WATER BABIES, THE BREADWINNER with George Cole, GINGERBREAD LADY with Marti Caine, MR AND MRS NOBODY, with Patricia Routledge and Clive Swift, MOVE OVER MRS MARKHAM, GASLIGHT, THE SPIDER’S WEB (on the occasion of the Agatha Christie 100th anniversary celebrations), WITNESS FOR THE PROSECUTION, THE LAST OF THE RED HOT LOVERS, directed by and starring Tom Conti, CHARLIE’S AUNT with Christopher Biggins, two versions of LORD ARTHUR SAVILE’S CRIME directed by Christopher Luscombe (firstly starring Russ Abbot, then Lee Dean) and HALF A SIXPENCE with .Gary Wilmot. His work for The Hiss and Boo Company includes back cloths for Music Hall and many Pantomimes. He designed the back cloth for the Reduced Shakepeare Company’s HOLLYWOOD IN AN HOUR.
           </Typography>
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
           Work for The Royal Academy of Dramatic Art includes: GASLIGHT, THE GHOST TRAIN, JUNO AND THE PAYWork for The Royal Academy of Dramatic Art includes: GASLIGHT, THE GHOST TRAIN, JUNO AND THE PAYCOCK, MY MOTHER SAID…., AS YOU LIKE IT, CAT AND CANARY, TONIGHT AT 8.30, THARK, DAISY PULLS IT OFF, HOBSON’S CHOICE, HOTEL PARADISO (by Feydeau  done in the round), A CHORUS OF DISAPPROVAL and FANNY’S FIRST PLAY. He has also designed HOBSON’S CHOICE and THE EPSON DOWNS for Drama Centre London.
           </Typography>
           <Paper className={classes.paperportrait}> 
                <img src={design} alt="Design Work" style={{width:'100%', height:'auto'}}/>

            </Paper>
           
           <Typography variant="body2" className={classes.textBlock} gutterBottom>
            He has done a series of minimalist flexible  settings, as well as costumes, for many RADA Enterprise productions for Cunard cruises, including ROMEO AND JULIET, UNDER MILKWOOD, MACBETH, RICHARD III, THE IMPORTANCE OF BEING EARNEST, PRIVATE LIVES, TONIGHT AT 8.30, FALLEN ANGELS, CHECKHOV ONE ACT PLAYS (The Bear and The Yalta Game), OTHELLO and several adaptations of Dickens’ novels.
            </Typography>
            <Typography variant="body2" className={classes.textBlock} gutterBottom>
            Recently he has delivered a workshop in special paint effect techniques for the Royal Opera House, and created a mask for King Mouse from The Nutcracker for The London Studio Centre . He is an experienced theatrical mask maker.
             </Typography>
             <Typography variant="body2" className={classes.textBlock} gutterBottom>
             Alexander is also an accomplished designer and painter of murals and a skilled decorative effects painter.
              </Typography>
          <Divider />
        
        </Grid>
        {/* End main content */}
        <Hidden smDown>
        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography variant="h6" gutterBottom>
              Alexander McPherson
            </Typography>
            
            <Card className={classes.card}>
            <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={amcp }
                    title="Alexander McPherson"
                    style={{width:'100%', height:'100%'}}
                  />
                  
           </Card>
          </Paper>
       
          <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
            Theatre Gallery
          </Typography>
        
          <Card className={classes.card}>
          <CardActionArea component={Link} to="/theatregallery">
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={abshell}
                    title="Absolute Hell"
                  />
            </CardActionArea>
          </Card>
         
          
        </Grid>
        </Hidden>
        </Grid>
        </div>
      </React.Fragment>
      )
}

export default withStyles(styles)(Theatre);