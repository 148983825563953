import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import indigo from '@material-ui/core/colors/indigo';
import acting from '../imgs/teachyourselfacting.png';
import fairy from '../imgs/thefairy.jpg'
import amcp from '../imgs/amoh.jpg';
const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: theme.palette.grey[50],
  },
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3,
    padding:  theme.spacing.unit,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 200,
  //paddingTop: '56.25%', // 16:9, marginTop:'30' 
   // height: 400,
    width: 400,
  },
  sideCardMedia: {
    height: 300,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    align: 'center'
  }, 
  quoteBlock: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    fontFamily: "Varela+Round"
  }, 
  paper: {
    padding: 3
  },
  paperportrait: {
    padding: 2,
    width:'60%',
    margin: 'auto',
  }
});


function References(props) {
    const { classes } = props;
  
    return (
      <React.Fragment> 
       <CssBaseline />
       <div className={classes.layout}>
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                  <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  References
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                  References
                  </Typography>
                </Hidden>
                </div>
              </Grid>
            </Grid>
        </Paper>
        
        <Grid container spacing={40} className={classes.mainGrid}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
        <Paper elevation={0} className={classes.sidebarAboutBox}>
          <Typography variant="subheading" className={classes.quoteBlock}  gutterBottom>
          <blockquote>
          &ldquo;
          Alex McPherson has a rare combination of remarkable skills as a craftsman, rich imagination as an artist, and profoundly wise and witty insights into the ways of the world born of his many years as one of the most admired scenic designers in British professional theatre. 
          <br />
          <br />
            His designs have brought style, colour and elegance to many of my productions, and his work as a mentor has hugely enriched the training of the next generation of creative artists.
            &rdquo;
        </blockquote>
        </Typography>
        </Paper>
        <div style={{ marginLeft: '20px'}}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
            Ellis Jones Former Vice-Principal R.A.D.A. and Artistic Director, RADA Cunard project.<br />
            Visit Ellis Jones' <a href='http://teachyourselfacting.com'>Teach Yourself Acting website</a>
          </Typography>
         </div>
         <Card className={classes.card} component={Link} to="/teachyourself">
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={acting }
                    title="Alexander McPherson"
                    style={{width:'100%', height:'auto'}}
                  />
                  
           </Card>
       
            <br />
          <Divider />
          <Paper elevation={0} className={classes.sidebarAboutBox}>
          <Typography variant="subheading" className={classes.quoteBlock}  gutterBottom>
          <blockquote>
          &ldquo;
          I have been lucky enough to have Alex create beautiful and unique additions to my home. We worked together originally in the theatre where he designed with great skill several plays that I directed. In my home he transformed two Victorian black slate fireplaces with a marble effect that is never questioned as to it's authenticity by all who visit.
          <br />
          <br />
            A very ordinary modern dresser is now highly painted and decorated in the Romanian style, and to the astonishment of all visitors the kitchen walls are a trellis of trees and exotic birds. Alex brings his own creativity to all his projects but is also very adaptable to the ideas and requirements of his clients. A skill borrowed from Theatre, where director and designer are a team.
            &rdquo;
         </blockquote>
          </Typography>
         </Paper>
          <div style={{ marginLeft: '20px'}}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
          Bardy Thomas Former Dean of Studies at R.A.D.A. (ret'd); freelance voice and speech coach
          </Typography>
          </div>
          <Divider />
          <Paper elevation={0} className={classes.sidebarAboutBox}>
          <Typography variant="subheading" className={classes.quoteBlock}  gutterBottom>
          <blockquote>
          &ldquo;I had the honour of meeting and working with the designer and painter Alexander Macpherson during my years as the Head of Scenic Art at The Royal Academy of Dramatic Art.
          <br />
          <br />
My working relationship has most thankfully been able to continue with him since then and I consider him as one of the most inspirational colleagues I have ever had the privilege of working with, and for.
<br />
          <br />
This working relationship extended well beyond my personal working relationship to many of my ex students that Alexander continues to both employ and inspire.
<br />
          <br />
His unquenchable thirst for excellence in all that he does, his energy and passion for his subject, as well as being a fount of knowledge of such a wide range of subjects has always been so respected and admired.
<br />
          <br />
Alexander always pushed the boundaries in his creative quest in order to achieve excellence in all he does. A multi talented individual, crossing the bridge in theatre, in scenic painting and design, across to decorative artwork and interiors. Alexander makes it seem effortless. So much of his great body of work and paintings are much admired and appreciated, both for their design, the skill and confidence of his brushes, his acutely knowledgeable eye for detail and quest for beauty and excellence.
<br />
          <br />
May we long continue to learn and gain great pleasure from this highly respected and talented man.
&rdquo;
</blockquote>
        </Typography>
        </Paper>
        <div style={{ marginLeft: '20px'}}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
      
          Emma Troubridge Head Scenic Artist. Royal Opera House.
        
          </Typography>
          </div>
          <Divider />
          <Paper elevation={0} className={classes.sidebarAboutBox}>
          <Typography variant="subheading" className={classes.quoteBlock}  gutterBottom>
          <blockquote>
          &ldquo;

I have known Alexander McPherson professionally and socially for over 25 years. During that time he has undertaken many varied commissions for me and for theatre companies with which I have been involved: everything from full scale sets for stage plays to backcloth painting, marbling and trompe l’oeil work.
<br />
          <br />
He is a craftsman of the first order and I have never had a problem with his work.
<br />
          <br />
His creativity and vision for design is exceptional and his execution of design to reality is first class.
<br />
          <br />
Alex is a delight to work with … and he’s an excellent judge of a good pint of beer!
&rdquo;
</blockquote>
    </Typography>
    </Paper>
    <div style={{ marginLeft: '20px'}}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
          Ian Liston Actor, Producer and  Artistic Director of the Hiss and Boo Theatre Company.  Private client
          </Typography>
          <Divider />
           </div>
        
        </Grid>
        {/* End main content */}
        <Hidden smDown>
        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography variant="h6" gutterBottom>
              Alexander McPherson
            </Typography>
            
            <Card className={classes.card}>
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={amcp }
                    title="Alexander McPherson"
                    style={{width:'100%', height:'100%'}}
                  />
                  
           </Card>
          </Paper>
       
          <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
            Galleries
          </Typography>
        
          <Card className={classes.card}>
          <CardActionArea component={Link} to="/gallery">
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={fairy}
                    title="The Fairy"
                  />
            </CardActionArea>
          </Card>
         
          
        </Grid>
        </Hidden>
        </Grid>
        </div>
      </React.Fragment>
      )
}

export default withStyles(styles)(References);