import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuralGalleryDisplay from './subpages/MuralGalleryDisplay';
import indigo from '@material-ui/core/colors/indigo';
import Hidden from '@material-ui/core/Hidden';
const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
 
 
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: theme.palette.grey[50],
  },

  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  mainFeaturedPost: {
    //backgroundImage: `url(${onei})`,
  //  backgroundRepeat: 'no-repeat',
  //  backgroundSize: 'cover',
    //backgroundColor: '#534846',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },

});



function MuralGallery(props) {
  const { classes } = props;

  return (
    <React.Fragment>
     
     <CssBaseline />
       <div className={classes.layout}>
    
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                
                  <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  Murals & Decorative Work Gallery
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                  Murals & Decorative Work Gallery
                  </Typography>
                </Hidden>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <MuralGalleryDisplay />
      </div>
      
    </React.Fragment>
  );
}

MuralGallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MuralGallery);

