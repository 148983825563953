import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import indigo from '@material-ui/core/colors/indigo';

import mask2 from '../imgs/Image154.jpg';
import mask from '../imgs/mask.jpg';
import kingmouse from '../imgs/kingmouse.jpg';

import maskside from '../imgs/img34.jpg';
import amcp from '../imgs/alexandermcpherson.jpeg';

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    backgroundColor: theme.palette.grey[50],
  },
  mainFeaturedPost: {
    //backgroundColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3,
    padding:  theme.spacing.unit,
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    height: 200,
  //paddingTop: '56.25%', // 16:9, marginTop:'30' 
   // height: 400,
    width: 400,
  },
  sideCardMedia: {
    height: 300,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  }, 

  paper: {
    padding: 2
  },
  paperportrait: {
    padding: 2,
    width:'60%',
    margin: 'auto',
  }
});


function Masks(props) {
    const { classes } = props;
  
    return (
      <React.Fragment> 
       <CssBaseline />
       <div className={classes.layout}>
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
                <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  Masks
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h3" color="inherit" gutterBottom>
                  Masks
                  </Typography>
                </Hidden>
                  
                </div>
              </Grid>
            </Grid>
        </Paper>
        
        <Grid container spacing={40} className={classes.mainGrid}>
        {/* Main content */}
        <Grid item xs={12} md={8}>
          <Typography variant="body2" className={classes.textBlock}  gutterBottom>
          Examples of Alexander's mask work can be seen here. His speciality is Commedia and he has recently done a series of Commeida Masks for a production of "The Taming Of The Shrew". He also creates masks and grotesques for walls and water features, and has recently completed a mask based on The Green Man for a water cistern fixed on to a brick wall in a private garden.  </Typography>
         
     
            <Paper className={classes.paper}> 
                <img src={mask} alt="Mask" style={{width:'100%', height:'auto'}}/>

            </Paper>
              
            <Paper className={classes.paper}> 
                <img src={kingmouse} alt="King Mouse" style={{width:'100%', height:'auto'}}/>

            </Paper>
       
        
            <Paper className={classes.paperportrait}> 
                <img src={mask2} alt="Mask" style={{width:'100%', height:'auto'}}/>

            </Paper>
    
          
          <Divider />
        
        </Grid>
        {/* End main content */}
        <Hidden smDown>
        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography variant="h6" gutterBottom>
              Alexander McPherson
            </Typography>
           
            <Card className={classes.card}>
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={amcp }
                    title="Alexander McPherson"
                  />
                  
           </Card>
          </Paper>
       
          <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
            Mask Gallery
          </Typography>
        
          <Card className={classes.card}>
                <CardActionArea component={Link} to="/maskgallery">
                  <CardMedia
                    className={classes.sideCardMedia}
                    component="img"
                    image={maskside}
                    title="Mask"
                  />
                  </CardActionArea>
                  
          </Card>
         
          
        </Grid>
        </Hidden>
        </Grid>
        </div>
      </React.Fragment>
      )
}

export default withStyles(styles)(Masks);