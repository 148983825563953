import React from "react";
import Gallery from "react-photo-gallery";
import Lightbox from 'react-images';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';


import theatre1 from '../../imgs/Gallery/KingLear2.jpeg';
import theatre2 from '../../imgs/Gallery/KingLear3.jpeg';
import theatre3 from '../../imgs/Gallery/kinglear.jpg';
import theatre4 from '../../imgs/Gallery/EasyVirtue1.jpeg';
import theatre5 from '../../imgs/Gallery/EasyVirtue2.jpeg';
import theatre6 from '../../imgs/Gallery/EasyVirtue3.jpeg';
import theatre7 from '../../imgs/Gallery/raftofthemedusa2014.jpg';
import theatre8 from '../../imgs/Gallery/raftofthemedusa20142.jpg';
import theatre9 from '../../imgs/Gallery/raftofthemedusa4.jpg';
import theatre10 from '../../imgs/Gallery/raftofthemedusa3.jpg';
import theatre11 from '../../imgs/Gallery/unnamed.jpg';
import theatre12 from '../../imgs/Gallery/unnamed2.jpg';
import theatre13 from '../../imgs/Gallery/panto1.jpg';
import theatre14 from '../../imgs/Gallery/mikado.jpg';
import theatre15 from '../../imgs/Gallery/IOBE1.jpg';
import theatre16 from '../../imgs/Gallery/earnest.jpg';
import theatre17 from '../../imgs/Gallery/Image34.jpg';
import theatre18 from '../../imgs/Gallery/applecart.jpg';
import theatre19 from '../../imgs/Gallery/0002A4.jpg';
import theatre20 from '../../imgs/Gallery/0904A4.jpg';
import theatre21 from '../../imgs/Gallery/1165A4.jpg';
import theatre22 from '../../imgs/Gallery/7253.JPG';
import theatre23 from '../../imgs/Gallery/7304.JPG';
import theatre24 from '../../imgs/Gallery/7131.JPG';
import theatre25 from '../../imgs/Gallery/abshell.jpg';
import theatre26 from '../../imgs/Gallery/abshell2.jpg';
import theatre27 from '../../imgs/Gallery/Image38.jpg';
const styles = theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      
    },
});

const photos = [
  {
    src: `${theatre1}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre2}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre3}`,
    width: 3,
    height: 4
  },
  {
    src: `${theatre5}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre4}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre6}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre15}`,
    width: 4,
    height: 3,
    alt: "Importance Of Being Ernest"
  },
  {
    src: `${theatre22}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre23}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre24}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre25}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre26}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre7}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre9}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre10}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre8}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre11}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre12}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre16}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre17}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre18}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre19}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre20}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre21}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre13}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre14}`,
    width: 4,
    height: 3
  },
  {
    src: `${theatre27}`,
    width: 4,
    height: 3
  }
];

// to best see the results, click the popout button in the preview browser
// and resize your browser

function columns(containerWidth) {
  let columns = 1;
  if (containerWidth >= 500) columns = 2;
  if (containerWidth >= 900) columns = 3;
  if (containerWidth >= 1500) columns = 4;
  return columns;
}



class TheatreGalleryDisplay  extends React.Component {
    constructor(props) {
      super(props);
      this.state = { currentImage: 0 };
      this.closeLightbox = this.closeLightbox.bind(this);
      this.openLightbox = this.openLightbox.bind(this);
      this.gotoNext = this.gotoNext.bind(this);
      this.gotoPrevious = this.gotoPrevious.bind(this);
    }
    openLightbox(event, obj) {
      this.setState({
        currentImage: obj.index,
        lightboxIsOpen: true,
      });
    }
    closeLightbox() {
      this.setState({
        currentImage: 0,
        lightboxIsOpen: false,
      });
    }
    gotoPrevious() {
      this.setState({
        currentImage: this.state.currentImage - 1,
      });
    }
    gotoNext() {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    }
    render() {
      const { classes } = this.props;
      return (
    
         <React.Fragment> 
          <CssBaseline />
          <div className={classes.layout}>
          <Gallery photos={photos} onClick={this.openLightbox} columns={columns}/>
          <Lightbox images={photos}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
        </React.Fragment>
      )
    }
  }
  
export default withStyles(styles)(TheatreGalleryDisplay);