import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import Hidden from '@material-ui/core/Hidden';
//
import theatreImg from '../imgs/easyvirtueact2_a.jpeg';
import galpic from '../imgs/iobe3.JPG';
import maskImg from '../imgs/kingmouse.jpg'
const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
 
 
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  mainFeaturedPost: {
    /*backgroundImage: `url(${bg })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',*/
    //backgroundColor: '#534846',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
});



function Home(props) {
  const { classes } = props;

  return (
    <React.Fragment>
     
      <main>
    
        <Paper className={classes.mainFeaturedPost}>
            <Grid container>
              <Grid item md={10}>
                <div className={classes.mainFeaturedPostContent}>
              
                
                  <Hidden only={['md','lg','xl']}>
                  <Typography  variant="h6" color="inherit" gutterBottom>
                  Alexander McPherson has worked in the theatre since the age of 14 and was for many years the Head of Design at the Bristol Old Vic. He has been head of design at the Theatre Royal Windsor and the Redgrave Theatre Farnham, and has created designs for The Welsh National Opera and the Royal Academy of Dramatic Art (RADA).  He is also an experienced mask maker.
                  </Typography>
                </Hidden>
                <Hidden only={['xs','sm']}>
                  <Typography  variant="h5" color="inherit" gutterBottom>
                  Alexander McPherson has worked in the theatre since the age of 14 and was for many years the Head of Design at the Bristol Old Vic. He has been head of design at the Theatre Royal Windsor and the Redgrave Theatre Farnham, and has created designs for The Welsh National Opera and the Royal Academy of Dramatic Art (RADA). He is also an experienced mask maker.
                  </Typography>
                </Hidden>
                </div>
              </Grid>
            </Grid>
          </Paper>
        <div className={classNames(classes.layout, classes.cardGrid)}>
      
          {/* End hero unit */}
          <Grid container spacing={40}>
            
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={theatreImg}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Theatre
                    </Typography>
                    <Typography>
                      Set and Costume designs for Plays, Opera and Pantomine.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="medium" variant="contained" color="secondary" className={classes.margin} component={Link} to="/theatre">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={maskImg}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Masks
                    </Typography>
                    <Typography>
                      Mask design.
                    </Typography>
                  </CardContent>
                  <CardActions>
                  <Button size="medium" variant="contained" color="secondary" className={classes.margin}  component={Link} to="/masks">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
               
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={galpic}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Galleries
                    </Typography>
                    <Typography>
                      Photos from Theatre and Mask Work.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="medium" variant="contained" color="secondary" className={classes.margin}  component={Link} to="/gallery">
                      View
                    </Button>
                    
                    
                  </CardActions>
                </Card>
              </Grid>

              
          </Grid>
        </div>
      </main>
    
    </React.Fragment>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);