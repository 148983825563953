import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

function pxToRem(value) {
  return `${value / 16}rem`;
}
//const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1E1716',
      },
      secondary: {
        main: '#424242',
      },
    },
  /*  breakpoints,
    MuiTypography: {
      h1: {
        fontSize: pxToRem(24),
        [breakpoints.up("md")]: {
          fontSize: pxToRem(32)
        }
      }
    }*/
      
  })


ReactDOM.render(
      
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
     ,
       document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
